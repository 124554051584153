import 'src/styles/reset.css';
import 'src/styles/general.css';

export const onInitialClientRender = () => {
  const trackdeskConfig = {
    linkId: `lp_911200`,
    clickId: 'clickId',
    sourceId: `jan-szczesniewicz`,
    tenantId: `fena-business-toolki`,
  };
  const params = new URLSearchParams(trackdeskConfig);

  const handleClick = (event) => {
    if (window.location.pathname.includes(`affiliate-signups`)) {
      const target = event.target.closest('a, button');
      if (
        target &&
        target.href &&
        (target.href.includes('sign-up') || target.href.includes('sign-in'))
      ) {
        const url = new URL(target.href);
        params.forEach((value, key) => {
          url.searchParams.set(key, value);
        });
        event.preventDefault();
        window.open(url.toString(), '_blank');
      }
    }
  };

  document.addEventListener('click', handleClick);
  return () => {
    document.removeEventListener('click', handleClick);
  };
};
